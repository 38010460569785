import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Layer,
  TextInput,
  FormField,
  Select,
  Text,
} from "grommet";
import { useSWRConfig } from "swr";
import Loader from "../../components/Loader";
import _ from "lodash";
// Constants
const DEBOUNCE_DELAY = 300; // Delay in ms for debouncing async search
const URL = "/api/appsflyer/agency-pub-opt";

// Agency Options
const AGENCIES = [
  { id: 0, name: "CYGOBELMEDIA" },
  { id: 1, name: "MOBLICA" },
  { id: 2, name: "CLEARPIERPERFOR" },
  { id: 3, name: "KPMBROMEDIA" },
  { id: 4, name: "VIDOMO" },
  { id: 5, name: "LOPOEMEDIA" },
];

// Fetch publishers based on app bundle and agency
async function fetchPublishersOptions(appBundle,agencyId,searchKey) {
  const res = await fetch(
    `${URL}/publishers-options?appBundle=${appBundle}&agencyId=${agencyId}&searchKey=${searchKey}`
  );
  return res.json();
}

// Fetch app bundle options based on search key
async function fetchBundlesOptions(searchKey) {
  const res = await fetch(`${URL}/bundles-options?searchKey=${searchKey}`);
  return res.json();
}

/**
 * Create or Edit Publisher Optimization Dialog Component
 * @param {boolean} show - Boolean to show/hide dialog
 * @param {function} setShow - Function to set visibility of the dialog
 * @param {object} pub - Existing publisher optimization data for editing
 * @param {function} setPub - Function to set publisher data in parent component
 */
export function CreateEditPubOptDialog({ show, setShow, pub, setPub }) {
  const { mutate } = useSWRConfig();

  // State for form fields and search keys
  const [appBundleSearchKey, setAppBundleSearchKey] = useState("");
  const [publishersSearchKey, setPublishersSearchKey] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  // Options for app bundles and publishers dropdowns
  const [appBundlesOptions, setAppBundlesOptions] = useState([]);
  const [publishersOptions, setPublishersOptions] = useState([]);

  // Form state variables
  const isEdit = Boolean(pub);
  const [appBundle, setAppBundle] = useState(pub ? pub.app_bundle : "");
  const [publishers, setPublishers] = useState(pub ? pub.publishers : []);
  const [ratio, setRatio] = useState(pub ? pub.desired_ratio : "");
  const [source, setSource] = useState(pub ? pub.source : "");
  const [agencyId, setAgencyId] = useState(
    pub ? pub.agency_id : undefined
  );

  // Fetch app bundle options with debouncing
  useEffect(() => {
    if (appBundleSearchKey && appBundleSearchKey.length > 2) {
      const debounceTimeout = setTimeout(() => {
        setIsLoading(true);
        fetchBundlesOptions(appBundleSearchKey).then((data) => {
          setAppBundlesOptions(data);
          setIsLoading(false);
        });
      }, DEBOUNCE_DELAY);

      return () => clearTimeout(debounceTimeout); // Clear timeout on key change or unmount
    }
  }, [appBundleSearchKey]);

  // Fetch publishers options with debouncing
  useEffect(() => {
    if (appBundle && agencyId !== undefined && publishersSearchKey.length > 2) {
      const debounceTimeout = setTimeout(() => {
        setIsLoading(true);
        fetchPublishersOptions(appBundle, agencyId, publishersSearchKey)
          .then((data) => { 
            setPublishersOptions(
              publishers? _.uniq([...data, ...publishers]) : data
            );
            setIsLoading(false);
          });
      }, DEBOUNCE_DELAY);

      return () => clearTimeout(debounceTimeout); // Clear timeout on key change or unmount
    }
  }, [publishersSearchKey, agencyId, appBundle, publishers]);

  // Initialize form fields when `pub` is updated
  useEffect(() => {
    if (pub) {
      setAgencyId(
        pub.agency_id
      );
      setAppBundle(pub.app_bundle);
      setPublishers(pub.publishers);
      setRatio(pub.desired_ratio);
      setSource(pub.source);
      setPublishersOptions(pub.publishers || []);
    }
  }, [pub]);

  // Reset all fields and close dialog
  function handleDialogClose() {
    setShow(false);
    setPub(undefined);
    setAppBundleSearchKey("");
    setPublishersSearchKey("");
    resetFields();
    setPublishersOptions([]);
    setAppBundlesOptions([]);
  }

  // Function to reset form fields
  function resetFields() {
    setAppBundle("");
    setPublishers([]);
    setRatio("");
    setSource("");
    setAgencyId("");
  }


  console.info({ publishers, publishersOptions })
  // Validation for form submission
  const isValid = isEdit
    ? pub.source !== source ||
    JSON.stringify(pub.publisher) !== JSON.stringify(publishers) ||
    parseInt(pub?.desired_ratio) !== parseInt(ratio) ||
    pub.agency_id !== AGENCIES.find((agency) => agencyId === agency.name)?.id
    : appBundle && ratio && source && agencyId !== undefined;

  console.info(isValid, )
  // Handle form submission
  async function handleFormSubmit(e) {
    e.preventDefault();

    let data = {
      app_bundle: appBundle,
      publishers,
      agencyId,
    };

    if (ratio !== pub?.desired_ratio) {
      data.ratio = ratio;
    }

    if (source !== pub?.source) {
      data.source = source;
    }

    try {
      let res;
      if (isEdit && isValid) {
        // Update existing entry
        res = await fetch(`/api/appsflyer/agency-pub-opt/${pub.rule_id}`, {
          method: "PUT",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(data),
        });
      } else {
        // Create new entry
        res = await fetch("/api/appsflyer/agency-pub-opt", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(data),
        });
      }

      console.info({ res });
      if (res.ok) {
        handleDialogClose();
        mutate("/api/appsflyer/agency-pub-opt");
      }
    } catch (err) {
      console.error("Error submitting form:", err);
    }
  }


  console.info({agencyId})

  return (
    <Box>
      {show && (
        <Layer onEsc={handleDialogClose} onClickOutside={handleDialogClose}>
          <Box width='large' pad='medium'>
            {/* Header for Dialog */}
            <Box
              style={{
                fontSize: "20px",
                fontWeight: "bold",
                textAlign: "center",
              }}
            >
              <div>{pub ? "Edit" : "Create"} Publisher Optimization Rule</div>
              {pub && (
                <div
                  style={{
                    fontSize: "16px",
                    fontWeight: "500",
                    marginTop: 8,
                  }}
                >
                  Editing App Bundle: {pub.app_bundle}
                </div>
              )}
            </Box>

            {/* Form for Create/Edit */}
            <form onSubmit={handleFormSubmit}>
              <Box pad='medium' margin={{ top: "10px" }}>
                {/* App Bundle Selection */}
                {!isEdit && (
                  <FormField label='App bundle'>
                    <Select
                      placeholder='Select App Bundle'
                      name='appBundle'
                      options={appBundlesOptions}
                      labelKey={(option) => (
                        <Box
                          style={{
                            padding: 11,
                            fontSize: 15,
                            display: "flex",
                            flexDirection: "row",
                          }}
                        >
                          <Text weight={600}>{option.app_name}</Text>::{" "}
                          <Text weight={600}>{option.app_bundle}</Text>
                        </Box>
                      )}
                      valueKey='app_bundle'
                      defaultValue={appBundle}
                      onSearch={(value) => setAppBundleSearchKey(value)}
                      onChange={({ option }) => {
                        setAppBundle(option.app_bundle);
                        setPublishers([]);
                      }}
                      emptySearchMessage={
                        <Text
                          color='gray'
                          weight={600}
                          size='20px'
                          style={{ padding: 11, fontSize: 15 }}
                        >
                          {!appBundleSearchKey ||
                            appBundleSearchKey.length < 3 ? (
                            "Type at least 3 characters to search"
                          ) : isLoading ? (
                            <Loader loading={true} />
                          ) : (
                            "No App Bundles found"
                          )}
                        </Text>
                      }
                    />
                  </FormField>
                )}

                {/* Agency Selection */}
                <FormField label='Agency'>
                  <Select
                    placeholder='Select agency'
                    name='agencyId'
                    options={AGENCIES}
                    value={AGENCIES.find(a => a.id === agencyId)}
                    onChange={({ option }) => setAgencyId(option.id)}
                    valueKey='id'
                    labelKey='name'
                  />
                </FormField>

                {/* Publisher Selection */}
                <FormField label='Publisher'>
                  {agencyId !== undefined && appBundle ? (
                    <Select
                      multiple
                      messages={
                        {multiple: publishers && publishers.length > 1 ? `${publishers.length} Publishers` : publishers ? publishers[0]: ''}
                      }
                      placeholder='Select publisher'
                      name='publishers'
                      options={publishersOptions}
                      onChange={({ value, option }) => {
                        let nextValue = value;
                        if (option && value.includes(undefined)) {
                          // undefined bug
                          nextValue = [...nextValue.filter(v => v), option]
                        }
                        setPublishers(nextValue)
                      }}
                      value={publishers}
                      onSearch={(value) => setPublishersSearchKey(value)}
                      emptySearchMessage={
                        <Text
                          color='gray'
                          weight={600}
                          size='20px'
                          style={{ padding: 11, fontSize: 15 }}
                        >
                          {!publishersSearchKey ||
                            publishersSearchKey.length < 3 ? (
                            "Type at least 3 characters to search"
                          ) : isLoading ? (
                            <Loader loading={true} />
                          ) : (
                            "No publishers found"
                          )}
                        </Text>
                      }
                    />
                  ) : (
                    <Text
                      color='gray'
                      weight={600}
                      style={{ padding: 11, fontSize: 15 }}
                    >
                      {isEdit
                        ? "Select publishers"
                        : "Select an App Bundle and Agency to see publishers options"}
                    </Text>
                  )}
                </FormField>

                {/* Source Selection */}
                <FormField label='Source'>
                  <Select
                    placeholder='Select source'
                    name='source'
                    options={["ftd", "reg"]}
                    value={source}
                    onChange={({ option }) => setSource(option)}
                  />
                </FormField>

                {/* Desired Ratio Input */}
                <FormField label='Desired ratio'>
                  <TextInput
                    value={ratio}
                    onChange={(e) => setRatio(e.target.value)}
                    placeholder='Enter number'
                    name='ratio'
                    type='number'
                  />
                </FormField>

                {/* Submit Button */}
                <Button
                  disabled={!isValid}
                  type='submit'
                  primary
                  style={{
                    marginTop: 20,
                    padding: 8,
                    textAlign: "center",
                    fontSize: "20px",
                    fontWeight: "500",
                  }}
                >
                  Submit
                </Button>
              </Box>
            </form>
          </Box>
        </Layer>
      )}
    </Box>
  );
}
