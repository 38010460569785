import { Box, Button, Layer } from "grommet";
import moment from "moment";

export function PubOptRulesDialog({ show, setShow, pub }) {
  return (
    <Box>
      {show && (
        <Layer
          onEsc={() => setShow(false)}
          onClickOutside={() => setShow(false)}
        >
          <Box width='large' pad='medium'>
            <Box>
              <div
                style={{
                  fontSize: "20px",
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                App bundle {pub.app_bundle}
              </div>
            </Box>

            <div style={{ fontSize: "18px", marginBottom: "10px" }}>
              Snapshots
            </div>
            <Box
              style={{
                maxHeight: "400px",
                overflowY: "auto",
                overflowX: "auto", // Enable horizontal scroll
              }}
            >
              {/* Wrap content in a Box with minWidth to prevent squeezing */}
              <Box style={{ minWidth: "1000px" }}>
                {/* Header Row */}
                <Box
                  direction='row'
                  gap='small'
                  style={{ color: "gray", fontSize: 12 }}
                  pad='small'
                >
                  <Box width='120px' align='center'>
                    Snapshot ID
                  </Box>
                  <Box width='170px'>Publisher</Box>
                  <Box width='80px' align='center'>
                    Acq
                  </Box>
                  <Box width='80px' align='center'>
                    Post
                  </Box>
                  <Box width='80px' align='center'>
                    Ratio
                  </Box>
                  <Box width='100px' align='center'>
                    Disabled
                  </Box>
                  <Box width='260px'>Created Time</Box>
                  <Box width='260px'>Updated Time</Box>
                </Box>

                {/* Data Rows */}
                {pub.snapshots.map((snapshot, index) => (
                  <Box
                    key={snapshot.snapshot_id}
                    direction='row'
                    gap='small'
                    pad='small'
                    border={
                      index !== pub.snapshots.length - 1
                        ? { side: "bottom", color: "light-4" }
                        : undefined
                    }
                    style={{ fontSize: 14 }}
                  >
                    <Box width='120px' align='center'>
                      {snapshot.snapshot_id}
                    </Box>
                    <Box width='170px' style={{ fontWeight: "600" }}>
                      {snapshot.publisher}
                    </Box>
                    <Box width='80px' align='center'>
                      {snapshot.acq}
                    </Box>
                    <Box width='80px' align='center'>
                      {snapshot.post}
                    </Box>
                    <Box width='80px' align='center'>
                      {snapshot.ratio}%
                    </Box>
                    <Box width='100px' align='center'>
                      {snapshot.post_disabled ? "Yes" : "No"}
                    </Box>
                    <Box width='260px'>
                      {moment(snapshot.created_time).format(
                        "YYYY-MM-DD HH:mm:ss"
                      )}
                    </Box>
                    <Box width='260px'>
                      {moment(snapshot.updated_time).format(
                        "YYYY-MM-DD HH:mm:ss"
                      )}
                    </Box>
                  </Box>
                ))}
              </Box>
            </Box>

            <Box align='center' margin={{ top: "medium" }}>
              <Button label='Close' onClick={() => setShow(false)} />
            </Box>
          </Box>
        </Layer>
      )}
    </Box>
  );
}
