import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Layer,
  TextInput,
  FormField,
  Select,
  Text,
} from "grommet";
import { useSWRConfig } from "swr";

/**
 * Create or Edit Publisher Optimization Dialog Component
 * @param {boolean} show - Boolean to show/hide dialog
 * @param {function} setShow - Function to set visibility of the dialog
 * @param {object} pub - Existing publisher optimization data for editing
 * @param {function} setPub - Function to set publisher data in parent component
 */
export function DeletePubOptDialog({ show, setShow, pub, setPub }) {
  const { mutate } = useSWRConfig();

  // Reset all fields and close dialog
  function handleDialogClose() {
    setShow(false);
    setPub(undefined);
  }

  // Handle form submission
  async function handleFormSubmit() {
    const response = await fetch(
      `/api/appsflyer/agency-pub-opt/${pub.rule_id}`,
      {
        method: "DELETE",
      }
    );

    if (response.ok) {
      // Invalidate the cache to trigger a re-fetch
      mutate("/api/appsflyer/agency-pub-opt");
      handleDialogClose();
    }
  }

  return (
    <Box>
      {show && (
        <Layer onEsc={handleDialogClose} onClickOutside={handleDialogClose}>
          <Box width="large" pad="medium">
            {/* Header for Dialog */}
            <Box
              style={{
                fontSize: "20px",
                fontWeight: "bold",
                textAlign: "center",
              }}
            >
              <div>
                {" "}
                Are you sure you want to delete this Pub Optimization Rule
              </div>
              {pub && (
                <div
                  style={{
                    fontSize: "16px",
                    fontWeight: "500",
                    marginTop: 8,
                  }}
                >
                  App Bundle: {pub.app_bundle}:{pub.app_name}
                </div>
              )}
            </Box>

            <Box
              direction="row"
              gap="small"
              justify="center"
              margin={{ top: "medium" }}
            >
              <Button
                style={{ padding: "2px 6px", borderRadius: 5 }}
                onClick={handleFormSubmit}
                primary
              >
                Delete
              </Button>
              <Button
                style={{ padding: "2px 6px", borderRadius: 5 }}
                onClick={handleDialogClose}
              >
                Cancel
              </Button>
            </Box>
          </Box>
        </Layer>
      )}
    </Box>
  );
}
