import React, { useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import useSWR from "swr";
import Moment from "moment";

// Grommet Components and Icons
import { Grommet, DataTable, Box, Button } from "grommet";
import { Edit, Trash } from "grommet-icons";

// Project-specific Components and Actions
import AppsFlyerActions from "../../actions/AppsFlyerActions";
import AppsFlyerCombinedActions from "../../actions/AppsFlyerCombinedActions";
import AppBarActions from "../../actions/AppBarActions";
import Cell from "../../components/Cell";
import { PubOptRulesDialog } from "./dialog";
import { CreateEditPubOptDialog } from "./CreateEditPubOpt";
import { DeletePubOptDialog } from "./DeleteDialog";

// Utilities and Constants
import { getFetcher } from "../../utils/fetch";
import { minimalTheme } from "../../themes/main-theme";

const agencies = [
  {
    id: 0,
    label: "CYGOBELMEDIA",
  },
  {
    id: 1,
    label: "MOBLICA",
  },
  {
    id: 2,
    label: "CLEARPIERPERFOR",
  },
  {
    id: 3,
    label: "KPMBRO",
  },
  {
    id: 4,
    label: "VIDOMO",
  },
  {
    id: 5,
    label: "LOPOEMEDIA",
  },
];

const agencyRender = (name) => (row) => {
  const agencyLabel = agencies.find(
    (agency) => agency.id === +row[name]
  )?.label;

  return <Cell style={{ textAlign: "center" }}>{agencyLabel}</Cell>;
};

// Helper Function for Formatting Dates
const dateRenderer = (name) => (row) =>
  (
    <Cell start style={{ width: "max-content" }}>
      {Moment(row[name]).format("YYYY-MM-DD HH:mm:ss")}
    </Cell>
  );

// Helper Function for Rendering Default Columns
const defaultRenderer = (name) => (row) =>
  <Cell style={{ minWidth: "80px" }}>{row[name]}</Cell>;

/**
 * Main Configuration Component
 * @param {object} props - Component properties, including Redux state and actions
 */
function Config(props) {
  // Fetch PubOpt data from the API using SWR
  const { data, error } = useSWR("/api/appsflyer/agency-pub-opt", getFetcher);

  // State Management
  const [show, setShow] = useState(false);
  const [showCreateEditDialog, setShowCreateEditDialog] = useState(false);
  const [showConfirmDeleteDialog, setConfirmDeleteDialog] = useState(false);
  const [pubOpt, setPubOpt] = useState();

  // If there is an error fetching the data, display an error message
  if (error) return <div>Error loading data!</div>;
  console.log(data);
  return (
    <Grommet
      theme={{
        ...minimalTheme,
        text: { extend: "font-size:12px" },
      }}
      style={{ width: "100%", maxHeight: "100%", overflow: "scroll" }}
    >
      <Box>
        {/* Action Button to Open the Create/Edit Dialog */}
        <Box direction="column" gap="small" pad="small">
          <Box direction="row" gap="small">
            <Button
              style={{ padding: "2px 6px", borderRadius: 5 }}
              primary
              onClick={() => setShowCreateEditDialog(true)}
            >
              + Create New Rule
            </Button>
          </Box>
        </Box>

        {/* Dialogs for Creating/Editing and Viewing PubOpt Rules */}
        <PubOptRulesDialog show={show} setShow={setShow} pub={pubOpt} />
        <CreateEditPubOptDialog
          show={showCreateEditDialog}
          setShow={setShowCreateEditDialog}
          pub={pubOpt}
          setPub={setPubOpt}
        />
        <DeletePubOptDialog
          show={showConfirmDeleteDialog}
          setShow={setConfirmDeleteDialog}
          pub={pubOpt}
          setPub={setPubOpt}
        />

        {/* DataTable for Displaying PubOpt Rules */}
        <Box fill style={{ overflowY: "auto" }}>
          <DataTable
            columns={getTableColumns({
              data,
              setPubOpt,
              setShow,
              setShowCreateEditDialog,
              setConfirmDeleteDialog,
            })}
            data={data}
            pad={{ horizontal: "medium" }}
            background={{
              body: ["white", "light-2"],
              footer: { dark: "light-2", light: "dark-3" },
            }}
          />
        </Box>
      </Box>
    </Grommet>
  );
}

/**
 * Helper Function to Define DataTable Columns
 * @param {object} params - Parameters including data and setters for state management
 * @returns {Array} Array of column definitions for DataTable
 */
function getTableColumns({
  data,
  setPubOpt,
  setShow,
  setShowCreateEditDialog,
  setConfirmDeleteDialog,
}) {
  return [
    {
      header: <Cell>Rule ID</Cell>,
      property: "rule_id",
      render: defaultRenderer("rule_id"),
    },
    {
      header: "App Bundle",
      property: "app_bundle",
      render: ({ app_bundle, app_name }) => (
        <Cell start>
          <div>{app_name}</div>
          <div style={{ fontSize: 11, color: "gray" }}>{app_bundle}</div>
        </Cell>
      ),
    },
    {
      header: <Cell>Agency ID</Cell>,
      property: "agency_id",
      render: agencyRender("agency_id"),
    },
    {
      header: "Desired Ratio",
      property: "desired_ratio",
      render: defaultRenderer("desired_ratio"),
    },
    {
      header: <Cell>Enabled</Cell>,
      property: "enabled",
      render: defaultRenderer("enabled"),
    },
    {
      header: "Publishers",
      property: "publishers",
      render: ({ publishers }) => (
        <Cell>
          {!publishers || !publishers.length ? "ALL" : publishers.length}
        </Cell>
      ),
    },
    {
      header: <Cell>Source</Cell>,
      property: "source",
      render: defaultRenderer("source"),
    },
    {
      header: "Created Time",
      property: "created_time",
      render: dateRenderer("created_time"),
    },
    {
      header: "Updated Time",
      property: "updated_time",
      render: dateRenderer("updated_time"),
    },
    {
      header: "Start Time",
      property: "start_time",
      render: dateRenderer("start_time"),
    },
    {
      header: "Snapshots",
      property: "snapshots",
      render: (pub) => (
        <Cell>
          <Button
            style={{
              margin: 4,
              padding: "3px",
              borderRadius: "5px",
              border: "1px solid #ccc",
            }}
            onClick={() => {
              setPubOpt(pub);
              setShow(true);
            }}
          >
            {pub.snapshots.length}
          </Button>
        </Cell>
      ),
    },
    {
      property: "",
      render: (pub) => (
        <Cell>
          <Button
            style={{
              cursor: "pointer",
              color: "black",
              border: "1px solid #ccc",
              padding: 6,
              borderRadius: 5,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            onClick={() => {
              setShowCreateEditDialog(true);
              setPubOpt(pub);
            }}
          >
            <Edit size="14px" color="black" />
          </Button>
        </Cell>
      ),
    },
    {
      property: "",
      render: (pub) => (
        <Cell>
          <Button
            style={{
              cursor: "pointer",
              color: "black",
              border: "1px solid #ccc",
              padding: 6,
              borderRadius: 5,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            onClick={() => {
              setConfirmDeleteDialog(true);
              setPubOpt(pub);
            }}
          >
            <Trash size="14px" color="black" />
          </Button>
        </Cell>
      ),
    },
  ];
}

/**
 * Map Redux state to component props
 * @param {object} state - The global state
 * @returns {object} The mapped state
 */
function mapStateToProps(state) {
  const {
    browser,
    appsflyer_adn: { constants: adnNetworks },
    appsflyer_combined: appsflyer,
  } = state;
  return { appsflyer, browser, adnNetworks };
}

/**
 * Map Redux actions to component props
 * @param {function} dispatch - Dispatch function from Redux
 * @returns {object} Bound action creators
 */
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      ...AppsFlyerActions,
      ...AppBarActions,
      ...AppsFlyerCombinedActions,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Config);
